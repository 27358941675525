import React from "react";
import { useInView } from "react-intersection-observer";
import "./mobileCombat.css";

const MobileCombat = () => {
  const combatVideo =
    "https://res.cloudinary.com/dav8yugzm/video/upload/v1709523001/output-trimmed_xul1bl.webm";

  const combatPhoto =
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1709580178/moki_combat_image_huqlr9.png";

  const { ref, inView } = useInView({
    threshhold: 0.1,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="main-mobile-combat-container">
      <div className="500px-mobile-combat-image-container">
        <img
          src={combatPhoto}
          alt="moki-combat"
          className="mobile-combat-photo"
        />
      </div>
      <div className={`mobile-combat-container ${inView ? "fade-in" : ""}`}>
        <h1 className="mobile-combat-text">Summon - Strategize - Succeed</h1>
        <p className="mobile-combat-description-text">
          Moki blends the art of monster taming with exhilarating card-based
          battles. See for yourself how to outsmart opponents and lead your
          creatures to victory.
        </p>
      </div>
      <div className="mobile-combat-video-container">
        <video
          src={combatVideo}
          className={`mobile-combat-video ${inView ? "fade-in" : ""}`}
          autoPlay
          loop
          muted
        />
        <img
          src={combatPhoto}
          alt="moki-combat"
          className="mobile-combat-photo"
        />
      </div>
    </div>
  );
};

export default MobileCombat;
