import React from "react";
import { useInView } from "react-intersection-observer";
import "./community.css";
import FlippedAngledDividerSVG from "../SVG-Shapes/flippedTrapezoide";

const Community = () => {
  const fillColor = "#272A30";
  const trainerImage =
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1709586483/moki-girl-trainer-website2_i6ibno.png";

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="main-community-container">
      <div className="community-image-container">
        <img
          src={trainerImage}
          className={`trainer-image ${inView ? "fade-in" : ""}`}
          alt="moki-trainer"
        />
      </div>
      <div
        className={`community-text-container ${inView ? "rightSlide-in" : ""}`}
      >
        <h1 className="community-title-text">Join - Discover - Stay Updated</h1>
        <p className="community-description-text">
          Joining our Discord community connects you to the pulse of the latest
          updates, where discovery meets real-time engagement. Stay up to date
          with latest developer news!
        </p>
      </div>
      <FlippedAngledDividerSVG fillColor={fillColor} />
    </div>
  );
};

export default Community;
