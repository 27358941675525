import React from "react";
import "./moki.css";

const Moki = () => {
  const fireMoki =
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1709416795/fire_dude_vpwscb.gif";

  return (
    <div className="giph-container">
      <img src={fireMoki} className="moki-gif" alt="fire-moki" />
    </div>
  );
};

export default Moki;
