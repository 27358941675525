import React from "react";
import "./mobileCommunity.css";
import { useInView } from "react-intersection-observer";

const MobileCommunity = () => {
  const mobileTrainerImage =
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1709586483/moki-girl-trainer-website2_i6ibno.png";

  const mobileTrainerImage2 =
    "https://res.cloudinary.com/dav8yugzm/image/upload/v1709586483/moki-girl-trainer-website2_i6ibno.png";

  const { ref, inView } = useInView({
    threshhold: 0.1,
    triggerOnce: true,
  });

  return (
    <div ref={ref} className="mobile-main-community-container">
      <img
        src={mobileTrainerImage2}
        className="mobile-trainer-image2"
        alt="moki-trainer"
      />
      <div className={`mobile-community-text-container ${inView ? "fade-in" : ""}`}>
        <h1 className="mobile-community-title-text">
          Join - Discover - Stay Updated
        </h1>
        <p className="mobile-community-description-text">
          Joining our Discord community connects you to the pulse of the latest
          updates, where discovery meets real-time engagement. Stay up to date
          with latest developer news!
        </p>
      </div>
      <div className="mobile-community-image-container">
        <img
          src={mobileTrainerImage}
          className={`mobile-trainer-image ${inView ? "fade-in" : ""}`}
          alt="moki-trainer"
        />
      </div>
    </div>
  );
};

export default MobileCommunity;
