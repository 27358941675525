import { useState, useEffect } from "react";
import { useInView } from "react-intersection-observer";
import "./combat.css";

// npm install react-intersection-observer
// This was used to achieve the 'fade-into-view' effect when the user scrolls down to view this component.

const Combat = () => {
  const [startAnimation, setStartAnimation] = useState(false);
  const combatVideo =
    "https://res.cloudinary.com/dav8yugzm/video/upload/v1709523001/output-trimmed_xul1bl.webm";

  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  useEffect(() => {
    if (inView) {
      setStartAnimation(true);
    }
  }, [inView]);

  return (
    <div ref={ref} className="main-combat-container">
      <div
        className={`combat-text-container ${startAnimation ? "slide-in" : ""}`}
      >
        <h1 className="combat-title-text">Summon - Strategize - Succeed</h1>
        <p className="combat-description-text">
          Moki blends the art of monster taming with exhilarating card-based
          battles. See for yourself how to outsmart opponents and lead your
          creatures to victory.
        </p>
      </div>
      <div className="combat-image-container">
        <video
          src={combatVideo}
          className={`combat-image ${inView ? "fade-in" : ""}`}
          autoPlay
          loop
          muted
        />
      </div>
    </div>
  );
};

export default Combat;
