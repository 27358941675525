import "./App.css";
import LandingStart from "./components/LandingStart/landingStart";
import Moki from "./components/Moki/moki";
import Mobile from "./components/Mobile-Components/Mobile/mobile";
import MobileCombat from "./components/Mobile-Components/Mobile-Combat/mobileCombat";
import Combat from "./components/Combat/combat";
import Community from "./components/Community/community";
import MobileCommunity from "./components/Mobile-Components/Mobile-Community/mobileCommunity";

function App() {
  return (
    <div className="app-container">
      <div className="relative-container">
        <LandingStart />
        <Moki />
        <Mobile />
      </div>

      <Combat />
      <MobileCombat />
      <Community />
      <MobileCommunity />
    </div>
  );
}

export default App;
