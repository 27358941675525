import React from "react";
import "./trapezoid.css";

const FlippedAngledDividerSVG = ({ fillColor }) => {
  return (
    <svg
      className="right-angle-triangle"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 10"
      preserveAspectRatio="none"
    >
      <polygon fill={fillColor} points="0,0 100,10 100,0" />
    </svg>
  );
};

export default FlippedAngledDividerSVG;
