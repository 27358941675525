import React from "react";
import "./landingStart.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDiscord } from "@fortawesome/free-brands-svg-icons";

const LandingStart = () => {
  return (
    <div className="landingstart-container">
      <div className="discord-join-container">
        <div className="title-container">
          <h1 className="main-title-text">
            Moki - Early Access - Join the Discord.
          </h1>
        </div>
        <div className="lorem-ipsum-container">
          <p className="content-text">
            Secure your spot in the adventure early by joining our Discord
            community. Get exclusive access, latest updates, and connect with
            fellow enthusiasts eager to explore the Moki universe!
          </p>
        </div>
        <button
          className="discord-button"
          onClick={() => (window.location.href = "https://discord.moki.gg")}
        >
          <FontAwesomeIcon icon={faDiscord} className="discord-icon" />
          <h1 className="join-discord-text">Join Discord</h1>
        </button>
      </div>
    </div>
  );
};

export default LandingStart;
